<template>
  <div class="smart-farm">
    <!-- 导航栏 -->
    <Nav :isHome="false" pageName="about"></Nav>
    <!-- <CommonNav pageName="智慧农业"></CommonNav> -->
    <section>
      <img class="smart-farm-bg" src="../../assets/images/about/bg.png" alt="">
    </section>
    <section class="smart-farm-container">
      <div class="container-title">
        <h4>四福是谁</h4>
        <img src="../../assets/images/about/line.png" alt="">
      </div>
      <div class="smart-farm-article">
        哈尔滨四福科技有限公司（以下简称“四福科技”），成立于2019年7月，是一家专注于互联网开发、物联网解决方案、定制化设计前端传感器选型、多路径网络传输设计、大容量数据存储与分析、提供云平台及计算服务的高新技术企业，面向“农业、林业、水利、畜牧业”四大领域的企事业单位提供互联网、物联网、云计算等方向的一站式解决方案。
      </div>
      <div class="smart-farm-article">
        公司秉承“人才为本、致力创新”的发展宗旨，经过近几年的努力，发展成为人才集聚、技术领先、实力雄厚的高科技企业，是目前黑龙江省内最具潜力的“优秀民营科技企业”。2022年度成为规上软件和信息技术服务业企业。公司现有员工百余人，其中技术研发人员占比57%以上，围绕大数据与区块链领域持续推出新技术、新产品。主导产品包括：烘干塔大数据智能云平台、烘干塔深度学习以表视觉识别系统、智能水利大数据识别系统、智能水利视频控制系统、智能运营管理系统等。产品均为自主研发，技术含量高，90%以上产品拥有国家知识产权。其中，烘干塔大数据智能云平台产品，填补了国内智慧农业产业链粮食烘干关键环节上的技术空白，且产品性能、关键技术等与国内同类产品相比较，均处于国内领先水平。
      </div>
      <div class="smart-farm-article">
        与包括黑龙江省农科院、哈尔滨工业大学人工智能研究院有限公司、东北林业大学人工智能大数据实验室建立深度合作关系，实现校企紧密合作，深化产教融合，围绕人工智能、大数据、遥感技术、数字孪生、边缘计算等多个领域的关键技术进行攻关，完成科技成果转化项目二十余项。与百度、华为、浪潮、金蝶、超聚变、安恒等省内外多家公司建立了良好的合作关系，同时在政企和数据中心领域与运营商形成了良好的合作协同关系。
      </div>
      <div class="container-title">
        <h4>企业资质</h4>
        <img src="../../assets/images/about/line.png" alt="">
      </div>
      <ul class="container-list">
        <li>企业自主研发的边缘计算与云计算框架下视频识别水位系统项目成果获批2020年度哈尔滨市“雏鹰计划”暨科技企业培育专项项目；</li>
        <li>2022年06月获AAA级诚信经营示范单位；</li>
        <li>2022年10月通过省科技厅评定的高新技术企业认定；</li>
        <li>2022年12月获得知识产权管理体系认证证书；</li>
        <li>2023年02月成功通过CMMI3认证；</li>
        <li>2023年03月通过第一批黑龙江省科技型中小企业；</li>
        <li>2023年04月成为黑龙江省科技创新券服务机构入库企业；</li>
        <li>2023年05月由哈尔滨市人社局授予“哈尔滨市青年就业见习基地”；</li>
        <li>2023年07月成为第一批黑龙江省创新型中小企业；</li>
        <li>2023年08月获市商务局2022年度哈尔滨市支持平台经济发展企业；</li>
        <li>2023年10月与黑龙江省农业科学院畜牧研究所联合共建“种养结合一体化”循环农业产业技术研究院获科技厅备案；</li>
        <li>2023年12月获黑龙江省专精特新中小企业；</li>
        <li>2024年05月获哈尔滨市企业技术中心。</li>
        <li>知识产权情况:已授权发明专利3项，授理2项，实用新型专利1项，软件著作权10项，已授权商标6个。</li>
      </ul>
      <img class="awards" src="../../assets/images/about/imgFirst3.png" alt="">
    </section>
    <!-- 底部 -->
    <Footer></Footer>
  </div>
</template>

<script>
import Nav from '@/components/nav.vue'
// import CommonNav from '@/components/commonNav.vue'
import Footer from '@/components/footer.vue'

export default {
  data() {
    return {

    }
  },
  components: {
    Nav,
    // CommonNav,
    Footer
  },
  beforeDestroy() {
    window.scrollTo(0, 0)
  }
}
</script>

<style lang="scss" scoped>
.smart-farm {
  width: 100vw;
  padding-top: 80px;
}

.smart-farm-bg {
  width: 100%;
  height: 100%;
}

.smart-farm-container {
  width: 55%;
  max-width: 1178px;
  margin: 71px auto 139px;
  font-size: 22px;
  color: #808080;
  text-align: left;

  .container-title {
    width: 200px;
    text-align: center;
    margin: 0 auto;

  }

  h4 {
    font-size: 36px;
    color: #333333;
    font-weight: 800;
    padding-left: 10px;
  }

  .smart-farm-article {
    margin-top: 20px;
    margin-bottom: 20px;
    line-height: 2;
    text-align: justify;
    // text-align-last: justify;
    /*兼容ie*/
    text-justify: distribute-all-lines;
  }

  .container-list {
    line-height: 2;

    li {
      list-style: disc;
    }
  }

  .awards {
    width: 100%;
    margin-top: 127px;
  }
}</style>